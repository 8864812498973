import React from "react"
import '../styles/pagination.css'

const Pagination = ({articlesPerPage, totalArticles, paginate, activePage}) => {
    const pageNumbers = [];
    const isFirst = activePage === 1
    const isLast = activePage === Math.ceil(totalArticles / articlesPerPage)
    const lowerBound = activePage < 3 ? 0 : activePage-3;
    const upperBound = ((activePage + 4) < totalArticles) ? activePage + 4 : totalArticles; 

    for (let i = 1; i <= Math.ceil(totalArticles / articlesPerPage); i++){
        if (i > lowerBound && i < upperBound) {
            pageNumbers.push(i);
        }
    }

    return (
        <div className = "pagination-container">
            <ul className = "pagination">
                {pageNumbers.map(number => (
                    <li key = {number} className = "page-item">
                        <div onClick={() => paginate(number)} className = {`page-link ${activePage !== number ? "" : " active-page"}`}>
                            {number}
                        </div>
                    </li>
                ))}

            {!isFirst && (
                <div onClick={() => paginate(activePage-1)} className = "page-item page-link prev">
                    ← Sebelumnya
                </div>
            )}
            {!isLast && (
                <div onClick={() => paginate(activePage+1)} className = "page-item page-link next">
                   Selanjutnya →
                </div>
            )}
            </ul>

        </div>
    )
}

export default Pagination;